.main {
  margin: 10px;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: white;
  font-size: 20px;
  line-height: 100px;
  text-align: center;
}

.loading {
  background-color: gray;
}

.ok {
  background-color: green;
  transition-duration: 1s;
}

.bad {
  background-color: red;
  transition-duration: 1s;
}
